import React, { useState, useEffect } from 'react';
import { Typography, Paper, Grid, Box, CircularProgress, IconButton } from '@mui/material';
import { useSwipeable } from 'react-swipeable';
import { useStyles } from '../styles'; 
import ItemCard from './ItemCard';
import RentalCard from './RentalCard';
import ArrowLeftIcon from '@mui/icons-material/ArrowLeft';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import { circularSlice } from '../features/util/bannerHelpers';
import { backgroundColor } from '../theme';

export const determineItemsToShow = () => {
  // Adjust these values based on your design and preferences
  if (window.innerWidth < 600) {
    return 1;
  } else if (window.innerWidth < 960) {
    return 3;
  } else {
    return 4;
  }
};

const ItemBannerVerticalEndless = ({items, setXs}) => {
    const classes = useStyles();

    const num = setXs ? setXs : determineItemsToShow()

    const [itemShowing, setItemShowing] = useState([])
    const [isLoading, setIsLoading] = useState(true);


    useEffect(() => {
      if(items){
          setIsLoading(false)
      }
    }, [items]);

  
    return (
        <>
        {isLoading ?
            <Box sx={{display: 'flex', justifyContent: 'center' }}>
                <CircularProgress style={{ color: 'black' }}/>
            </Box> 
        :   
        <Grid container className={classes.infinitScroll} alignItems="center" justifyContent="center">
            {items.map((item, index) => 
              <Grid  
                key={item.id}
                xs={num - 0.5}
                mb={1}
                mt={1}
              >
                <ItemCard key={item.id} item={item} index={index} />
              </Grid>
            )}
        </Grid>
    }
    </>
    );
  };
  
  export default ItemBannerVerticalEndless;