import { useEffect, useState } from 'react';
import { useNavigate  } from 'react-router-dom'; 
import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
import { useStyles } from '../../../styles';
import { Button, CircularProgress, Grid, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions,  } from '@mui/material';
import { useParams } from "react-router-dom";
import { getRental } from '../../User/actions';
import { calculateTotalDays } from '../paymentHelper';
import { confirmPayment, createPaymentIntent, createStripeAccount } from '../actions';
import WelcomeBanner from '../../../components/WelcomeBanner';
import { useUser } from '../../User/userContext';


export default function BillingAccounts() {

  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(true)
  const [buttonLoading, setButtonLoading] = useState(false)


  const { user } = useUser();

  console.log(user)

  useEffect(() => {
    if(user){
        setIsLoading(false)
    }
  }, [user])

  const handleCreateStripeAccount = () => {
    setButtonLoading(true)
    createStripeAccount().then(response => {
        setButtonLoading(false)
        console.log(response)
        const { url } = response
        window.location.href = url;

    })
  }

  return (
    <>
    {isLoading ? <CircularProgress/> :
    <Grid container spacing={3} justifyContent="center">
        <Grid item xs = {10} mt={2}>
            <WelcomeBanner banners={['Billing Accounts']}/>
        </Grid>
        {
            user.user.stripeConnectId ? 
                <Grid container item xs = {10} mt={2}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={() => window.location.href = `https://dashboard.stripe.com/connect/accounts/${user.user.stripeConnectId}`}
                            >
                            Open Stripe Dashboard
                        </Button>
                </Grid>
            :
                <Grid container item xs = {10} mt={2}>
                    <Grid item>
                        No billing account created. By clicking the button bellow we will share your first name, last name, and email with Stripe. 
                        <Grid item mt = {2}>
                            <Button variant='contained' disabled = {buttonLoading} onClick = {() => {handleCreateStripeAccount()}}>
                                {buttonLoading ? 'Loading..' : 'Create Stripe Billing account.'} 
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>

        }
    </Grid>
  }
  </>
  );
}
