import axios from '../../axios.js'

export const createPaymentIntent = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/createpaymentintent/`,data)
    return response
}

export const confirmPayment = async (clientSecret, cardElement, stripe) => {
    const {error, paymentIntent} = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
            card: cardElement
        },
    });
    if (error) {
        console.log('Payment failed: ', error);
    } else if (paymentIntent.status === 'succeeded') {
        console.log('Payment succeeded!');
    }
}

export const cancelPaymentIntent = async (id) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/cancelpaymentintent/`,id)
    return response
}

export const confirmPaymentIntent = async (id) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/confirmpaymentintent/`,id)
    return response
}

export const createStripeAccount = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/createstripeaccount/`)
    return response.data
}

export const clearUserConnectId = async () => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/clearuserstripeaccount/`)
    return response.data
}

export const checkStripeAccount = async () => {
    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/checkstripeaccount/`)
    return response.data
}

export const getTaxRate = async (data) => {
    const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/gettaxrate/`, data)
    return response.data
}

export const updateReturnConfirmation = async (rentId, dateTime) => {
    const response = await axios.patch(`${process.env.REACT_APP_BACKEND_URL}/api/rent/${rentId}/`,{
        rental_return_confirmation_date:dateTime
    })
    return response.data
}