import React, { useEffect } from 'react';
import { useUser } from '../userContext';
import { Typography, Grid, Card, CardContent,Button, Divider, IconButton, TextField, InputAdornment, Avatar, Paper, CircularProgress } from "@mui/material";
import { useStyles } from '../../../styles';
import { useNavigate, useParams } from 'react-router-dom';
import Footer from '../../../components/Footer';
import { checkEmail, createUserProfile, editUserProfile, getUserInformation } from '../actions';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { mainHeadingColor } from '../../../theme';
import { ImageUpload } from '../../../components/ImageUpload';
import { getImageFromUrl } from '../../util/imageHelper';

export default function UserInformation(){
    const classes = useStyles()
    const navigate = useNavigate()

    const [disable, setDisable] = React.useState(true) 

    const { user, isAuthenticated, logout, location, setLocation } = useUser();

    const [isLoading, setIsLoading] = React.useState(true)

    const [userData, setUserData] = React.useState({})
    const [emailErrorMessage, setEmailErrorMessage]  = React.useState('')
    const [loading, setLoading] = React.useState(false)

    const [profilePicture, setProfilePicture] = React.useState([]);

    useEffect(() => {
        if(user){
            const fetchData = async () => {
                const response = await getUserInformation(user.user.id)
                if(response){
                    console.log(response)
                    setUserData(response)
                    console.log(response)
                    const file = await getImageFromUrl(response.profilePicture);
                    console.log(file)
                    if (file) { // Make sure the file is not false or undefined
                        setProfilePicture([{url: response.profilePicture, file}]);
                    }
                    setIsLoading(false)
                }
            }
            fetchData()
        }
    }, [user])

    // useEffect(() => {

    // }, [userData])

    const formatPhoneNumber = (value) => {
        const cleaned = value.replace(/[^\d]/g, '');
        if (cleaned.length <= 10) {
            const part1 = cleaned.slice(0, 3) ? `(${cleaned.slice(0, 3)}` : '';
            const part2 = cleaned.slice(3, 6) ? `) ${cleaned.slice(3, 6)}` : '';
            const part3 = cleaned.slice(6, 10) ? `-${cleaned.slice(6, 10)}` : '';
            const formattedNumber = `${part1}${part2}${part3}`;
            handleData('phoneNumber', formattedNumber);
        }
    };

    const handleCancel = () => {
        setIsLoading(true)
        const fetchData = async () => {
            const response = await getUserInformation(user.user.id)
            if(response){
                console.log(response)
                setUserData(response)
                const file = await getImageFromUrl(response.image);
                if (file) { // Make sure the file is not false or undefined
                    setProfilePicture([{url: response.image, file}]);
                }
                setIsLoading(false)
            }
        }
        fetchData()
    }

    const handleUserData = (field, data) => {
        let obj = {...userData}
        let userAuthData = {...obj.user}
        userAuthData[field] = data
        obj['user'] = userAuthData
        setUserData(obj)
    }

    const handleData = (field, data) => {
        let obj = {...userData}
        obj[field] = data
        setUserData(obj)
    }

    console.log(user)
    console.log(profilePicture)

    const handleSubmit = (e) => {
        e.preventDefault()
        const formData = new FormData();
        formData.append('first_name', userData.user.first_name);
        formData.append('last_name', userData.user.last_name);
        formData.append('profilePicture', profilePicture[0] ? profilePicture[0].file: null); // Assuming `profilePicture` is a File object
        formData.append('phoneNumber', userData.phoneNumber);
        setLoading(true)
        editUserProfile(user.user.user.id, formData).then(response=>{
            setLoading(false)
            setDisable(true)
            handleCancel()
            // navigate('/h/')
        })
    }

   
    return (
        <Grid container justifyContent={"center"}>
            <Grid item xs={10} sm={12} ms={12} lg={12} align="center">
                <Typography variant ="h2" >
                    Welcome to Yelo!
                </Typography>
                <Grid item cotnainer xs={10} mt={2}>
                        <Grid item xs={6}>
                            <Typography variant="h1">Personal Information</Typography>
                        </Grid>
                    </Grid>
            </Grid>
            { isLoading ?  <CircularProgress/> :
                <Grid container spacing = {1} item xs={10} sm = {6} md = {6} mt = {5}>
                    <Grid item xs={6} mb = {2}>
                            {<Button 
                                variant = "contained"
                                onClick={()=>{if(!disable){
                                    handleCancel()
                                }setDisable(!disable)}}
                            >   
                                {disable ? 'Edit Profile' : 'Cancel'}
                            </Button>}
                    </Grid>
                    <form onSubmit={handleSubmit}>
                    <Grid container spacing = {2} item xs={12}>
                    <Grid item xs={10}>
                        <Typography>First Name</Typography>
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField size="small" required disabled = {disable} value = {userData.user.first_name} fullWidth onChange={(e)=> handleUserData('first_name',e.target.value)}/>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography>Last Name</Typography>
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField size="small" required disabled = {disable} value = {userData.user.last_name} fullWidth onChange={(e)=> handleUserData('last_name',e.target.value)}/>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography>Phone Number</Typography> 
                    </Grid>
                    <Grid item xs={9} md ={6}>
                        <TextField size="small"  inputProps={{ inputMode: 'numeric' }} disabled = {disable} value = {userData.phoneNumber} required fullWidth onChange={(e)=> formatPhoneNumber(e.target.value)}/>
                    </Grid>
                    <Grid item xs={10}>
                        <Typography>Profile Picture (optional)</Typography> 
                    </Grid>
                    <Grid item xs={9} md ={9}>
                        <ImageUpload images={profilePicture} disabled = {disable} setImages={setProfilePicture} limit={1}/>
                    </Grid>

                    <Grid item xs={10} sm = {12} md = {12} mt={2}>
                        {loading ? <CircularProgress/> : 
                            !disable ? <Button variant = "contained" type="submit">Edit Profile</Button> : ''
                        }
                    </Grid>
                    </Grid>
                    </form>
                </Grid>
            }
        </Grid>

    );
};