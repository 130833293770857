import { Typography, Grid, Button, Box, Divider, IconButton, TextField, InputAdornment, Avatar, Menu, MenuItem } from "@mui/material";
import { useNavigate  } from 'react-router-dom'; 
import React from "react";
import { useState, useEffect } from "react";
import { useStyles } from '../styles'; 
import LocationOnIcon from '@mui/icons-material/LocationOn';
import ListAltIcon from '@mui/icons-material/ListAlt';
import SearchIcon from '@mui/icons-material/Search';
import { useUser } from '../features/User/userContext';
import { logoutUser } from "../features/User/actions";
import { mainComponentColor, mainHeadingColor } from "../theme";
import { initAutocomplete, loadScript } from "../features/util/locationHelpers";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { formatDateDB } from "../features/util/formatters";

export const getUserIcon = (isAuthenticated, user) => {
    if(isAuthenticated){
        if (user.image){
            return <img src={user.image} style={{ width: '100%', height: '100%', objectFit: 'cover' }} />
        }
        else{
            return `${user.user.first_name[0]}${user.user.last_name[0]}`;
        } 
    }
    return null;
}

export const getLocation = (setLocation) => {
    if (typeof navigator !== 'undefined' && "geolocation" in navigator) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
            setLocation(position)
        },
        (error) => {
            console.log("ERROR")
        }
      );
    } else {
        console.log("NO GEOLOCATION");
    }
  }; 


export default function Header(props) {

    const navigate = useNavigate();
    const classes = useStyles(); 
    const { user, isAuthenticated, logout, location, setLocation } = useUser();
    const [search, setSearch] = useState()
    const [anchorEl, setAnchorEl] = useState(null);

    const [searchLocation, setSearchLocation] = useState()
    const [selectedDate, setSelectedDate] = useState(null);
    
    useEffect(() => {
        // console.log(location)
    }, [user, location])

    const setSearchLocationFunction =(name, latitude, longitude, placeIdTemp) => {
            const obj = {
                addressName: name,
                latitude: latitude,
                longitude: longitude,
                place_id: placeIdTemp
            };
            // setSearchLocation(searchLocationData => ({ ...searchLocationData, ...obj }))
            setSearch((prevSearch) => ({
                ...prevSearch,
                addressName: name,
                latitude: latitude,
                longitude: longitude,
                place_id: placeIdTemp
            }));
    }

    useEffect(() => {
        // Check if Google Maps script is already loaded
        if (document.getElementById('google-maps-script')) {
            if (window.google && window.google.maps && window.google.maps.places) {
                initAutocomplete(setSearchLocationFunction);
            } else {
                loadScript(setSearchLocationFunction);
            }
        } else {
            loadScript(setSearchLocationFunction);
        }
    }, [document.getElementById('autocompleteInput')]);
  
    const handleClose = () => {
      setAnchorEl(null);
    };

    const handleHome = () => {
        navigate('/home/')
    }

    const handleSearchEnter = () => {
        console.log(search)
        const queryString = new URLSearchParams(search).toString();
        navigate(`search/?${queryString}`)
    }

    const handleOwnerProfile = () => {
        navigate('/ownerprofile/')
        handleClose()
    }

    const handleUser = (event) => {
        if (!isAuthenticated) {
            return navigate('/base/login/')
        }
        else{
            setAnchorEl(event.currentTarget);
        }
    }

    const handleLogout = ()=> {
        logoutUser().then(response => {logout(); navigate('home/'); handleClose()})
    }

    const handleUpload = () => {
        if (!isAuthenticated) {
            return navigate('/base/login/')
        }
        navigate('/base/upload/')
    }

    const handleKeyDown = (event) => {
        console.log('HERE')
        if (event.key === 'Enter') {
            const queryString = new URLSearchParams(search).toString();
            navigate(`search/?${queryString}`)
        }
    };

    const handleSearchDates = (field, event)=>{
        const newDate = formatDateDB(event)
        setSearch((prevSearch) => ({
            ...prevSearch,
            [field]: newDate,
        }));
    }


    const handleSearchText = (field, event) => {
        setSearch((prevSearch) => ({
            ...prevSearch,
            [field]: event.target.value,
        }));
    }

    console.log(search)

    
    return (
        <>
        <Grid container style = {{direction: 'row', alignItems: 'center', height: '100%', width:'100%', display: 'flex', justifyContent: 'space-between' }}>
            <Grid item xs = {2} sm = {1} md = {1}>
                <Button onClick = {() => handleHome()} style={{ textTransform: 'none' }}>
                    <Typography variant ="h1" align="center">
                        Yelo
                    </Typography>
                </Button>
            </Grid>
            <Grid
                item
                display={{ xs: "block", sm:'block', md: 'block' }} 
                sm = {0.5} 
                md = {0.5} 
                style={{ height: '100%' }}
            >
                <IconButton color="mainComponentColor" aria-label="map" onClick = {()=>{getLocation(setLocation)}}>
                    <LocationOnIcon style={{ color: location ? '#ebb134' : 'mainComponentColor' }}/> 
                    <Typography variant = 'locationText' style={{ color: location ? '#ebb134' : 'mainComponentColor' }}>
                        Meet near me
                    </Typography>
                </IconButton>
            </Grid>
            <Grid item xs={2.5} sm = {2} md = {2} ml ={0.5} style={{ height: '100%' }}>
                <IconButton color={mainComponentColor} aria-label="map" onClick = {()=>{handleUpload()}}>
                <Box 
                    component={Grid}
                    item
                    display={{ xs: "none", sm: "none", md :"block" }} 
                >
                    <ListAltIcon/>
                </Box>
                    <Typography variant = 'smallCleanText'>Rent your items</Typography>
                </IconButton>
            </Grid>
                <IconButton onClick = {(event) => {handleUser(event)}}>
                    <Avatar  style={{ backgroundColor: mainComponentColor }}>
                        {getUserIcon(isAuthenticated, user ? user.user: null)}
                    </Avatar>
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => {navigate('userinformation/')}}>
                        User Information
                    </MenuItem>
                    <MenuItem onClick={() => {handleOwnerProfile()}}>
                        Owner Profile
                    </MenuItem>
                    <MenuItem onClick={() => {navigate('renterprofile/')}}>
                        Renter Profile
                    </MenuItem>
                    <MenuItem onClick={() => {handleLogout()}} style={{ color: 'red' }}>
                        Logout
                    </MenuItem>
                </Menu>
        </Grid>
        { !props.noSearch ? 
        <Grid container style = {{direction: 'row', alignItems: 'center', height: '100%', width:'100%', display: 'flex', justifyContent: 'center' }}>
                <Grid container item ml = {1} mr={1} xs={12} sm = {9} md = {9} mb={1} style={{borderRadius: '20px', border:`1px solid ${mainHeadingColor}`, boxShadow: '0px 4px 12px rgba(0, 0, 0, 0.2)' }}>
                    <Grid item container xs={12} mb={0.5}>
                    <Grid item xs = {3} md = {3.5}>
                        <TextField
                            label='What' 
                            onChange={(event) => {handleSearchText('what', event)}}
                            InputProps={{
                                disableUnderline: true,
                                sx: {
                                    padding: '0 10px', // Optional padding
                                }
                            }}
                            variant="standard"
                            sx={{ 
                                "& .MuiInputLabel-root": { 
                                    textAlign: "center", 
                                    width: "100%",
                                },
                                "& .MuiInputBase-root": {
                                    borderRadius: "20px", // Adjust the radius as needed
                                },
                            }}
                            size='small'
                        />
                    </Grid>
                    <Grid item xs = {0.5} container alignItems="center" justifyContent="center">
                        <Divider 
                            orientation="vertical"
                            sx={{ height: '40%' }}
                        />
                    </Grid>
                    <Grid item xs = {3}>
                            <TextField
                                id="autocompleteInput" 
                                label='Meet near' 
                                // onChange={(event) => {handleSearchChange('where', event)}}
                                InputProps={{
                                    disableUnderline: true,
                                    endAdornment: <div id="google-maps-script"/>  
                                }} 
                                variant="standard"
                                sx={{
                                    "& .MuiInputLabel-root": { 
                                        textAlign: "center", 
                                        width: "100%" 
                                    }
                                }}
                                size='small'
                            />
                    </Grid>
                    <Grid item xs = {0.5} container alignItems="center" justifyContent="center">
                        <Divider 
                            orientation="vertical"
                            sx={{ height: '40%' }}
                        />
                    </Grid>
                    <Grid item xs = {1.75} md={1.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                        label="Pick up"
                        value={search ? search.pickup: null}
                        views={["month", "day"]}
                        onChange={(newValue) => handleSearchDates('pickup', newValue)}
                        slotProps={{ textField: { variant: 'standard',
                                                 InputProps:{disableUnderline: true}, 
                                                 sx:{"& .MuiInputLabel-root": { 
                                                        textAlign: "center", 
                                                        width: "100%" 
                                                    }},
                                                    size:'small'
                                                } 
                                }}
                    />
                    </LocalizationProvider>
                    </Grid>
                    <Grid item xs = {0.5} container alignItems="center" justifyContent="center">
                        <Divider 
                            orientation="vertical"
                            sx={{ height: '40%' }}
                        />
                    </Grid>
                    <Grid item xs = {1.75} md= {1.5}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <MobileDatePicker
                        label="Dropoff up"
                        value={ search ? search.dropoff: null}
                        views={["month", "day"]}
                        onChange={(newValue) => handleSearchDates('dropoff',newValue)}
                        slotProps={{ textField: { variant: 'standard',
                                                 InputProps:{disableUnderline: true}, 
                                                 sx:{"& .MuiInputLabel-root": { 
                                                        textAlign: "center", 
                                                        width: "100%" 
                                                    }},
                                                    size:'small'
                                                } 
                                }}
                    />
                    </LocalizationProvider>
                    </Grid>
                <Grid item xs = {1} mt={0.7} style={{ display: 'flex', justifyContent: 'flex-end' }}>
                    <IconButton onClick = {() => {handleSearchEnter(search)}}>
                        <SearchIcon/>
                    </IconButton>
                </Grid>
            </Grid>
            </Grid>
            </Grid>
            : null }
    </>
    )
}
